import SinglePost from './SinglePost'
import GalleryPlayer from './GalleryPlayer';
import { useState } from 'react';

const GalleryLayout = ({ feedSettings, posts, headerData, isPro, editorActiveDevice, isFeedEditor, feedId }) => {
    const [activePost, setActivePost] = useState(posts[0] || null);
    const [activeIndex, setActiveIndex] = useState(posts.length > 0 ? 0 : null);
    const [isPlaying, setIsPlaying] = useState(false);

    let desktopNumber = feedSettings.galleryDesktopColumns;

    if (isFeedEditor) {
        switch (editorActiveDevice.device) {
            case 'mobile':
                desktopNumber = feedSettings.galleryMobileColumns;
                break;
            case 'tablet':
                desktopNumber = feedSettings.galleryTabletColumns;
                break;
            default:
                desktopNumber = feedSettings.galleryDesktopColumns;
                break;
        }
    }

    //
    const handleGalleryPlayer = (postIndex) => {
        setActivePost(posts[postIndex]);
        setIsPlaying(true);
        setActiveIndex(postIndex);

        // scroll to GalleryPlayer. 
        const feedContainer = document.getElementById(`sb-tiktok-feeds-container-${feedId}`);
        if (feedContainer) {
            feedContainer.scrollIntoView({ behavior: 'smooth' });
        }

    }

    return (
        <>
            {
                activePost &&
                <GalleryPlayer
                    post={activePost}
                    headerData={headerData}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                />
            }

            <div
                className='sb-grid-wrapper sb-fs'
                data-grid-columns={desktopNumber}
                data-grid-tablet-columns={feedSettings.galleryTabletColumns}
                data-grid-mobile-columns={feedSettings.galleryMobileColumns}
                style={{
                    display: 'grid',
                    columnGap: `${feedSettings.horizontalSpacing}px`,
                    rowGap: `${feedSettings.verticalSpacing}px`
                }}
            >
                {
                    posts.map((post, postIndex) =>
                        <SinglePost
                            headerData={headerData}
                            post={post}
                            postIndex={postIndex}
                            feedSettings={feedSettings}
                            key={postIndex}
                            isPro={isPro}
                            isFeedEditor={isFeedEditor}
                            handlePostClick={handleGalleryPlayer}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        />
                    )
                }
            </div>
        </>
    )
}

export default GalleryLayout;