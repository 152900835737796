import Caption from './Caption';
import Likes from './Likes';
import Views from './Views';
import Author from './Author';

const PostDetails = ({
    isPro,
    post,
    feedSettings,
    headerData,
    postIndex,
    layout,
    isFeedEditor
}) => (
    <div className='sb-post-item-details'>

        {isPro && feedSettings?.postElements?.includes('author_info') && layout === 'list' &&
            <Author headerData={headerData} />
        }

        {isPro && feedSettings?.postElements?.includes('caption') &&
            <Caption
                post={post}
                postIndex={postIndex}
                feedSettings={feedSettings}
                isFeedEditor={isFeedEditor}
            />
        }

        {layout === 'list' && (
            <div className='sb-post-item-meta'>
                {isPro && feedSettings?.postElements?.includes('views') &&
                    <Views post={post} fillColor={'black'} />
                }
                {isPro && feedSettings?.postElements?.includes('likes') &&
                    <Likes post={post} fillColor={'black'} />
                }
            </div>
        )}
    </div>
);

export default PostDetails;