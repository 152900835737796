import SinglePost from './SinglePost'
import LightboxModal from './Elements/Modal';
import { useState } from 'react';
import { createPortal } from 'react-dom';

const ListLayout = ({ feedSettings, posts, headerData, isPro, isFeedEditor }) => {
    const [activePost, setActivePost] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [isLightboxVisible, setIsLightboxVisible] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    //
    const handleModalOpen = (postIndex) => {
        setActivePost(posts[postIndex]);
        setActiveIndex(postIndex);
        setIsLightboxVisible(true);
    }

    //
    const handleCloseModal = () => {
        setActivePost(null);
        setIsLightboxVisible(false);
        document.body.classList.remove('sbt-lightbox-modal-open');
    }

    //
    const handlePreviousItem = () => {
        const currentPostIndex = posts.findIndex(post => post.id === activePost.id);
        if (currentPostIndex === 0) {
            //
        }
        else {
            setActivePost(posts[currentPostIndex - 1]);
            setActiveIndex(currentPostIndex - 1);
        }
    }

    //
    const handleNextItem = () => {
        const currentPostIndex = posts.findIndex(post => post.id === activePost.id);
        if (currentPostIndex === posts.length - 1) {
            //
        }
        else {
            setActivePost(posts[currentPostIndex + 1]);
            setActiveIndex(currentPostIndex + 1);
        }
    }

    return (
        <>
            <div className='sb-list-wrapper sb-fs'>
                {
                    posts.map((post, postIndex) =>
                        <SinglePost
                            headerData={headerData}
                            post={post}
                            postIndex={postIndex}
                            feedSettings={feedSettings}
                            key={postIndex}
                            isPro={isPro}
                            isFeedEditor={isFeedEditor}
                            handlePostClick={handleModalOpen}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                        />
                    )
                }
            </div>

            {
                isLightboxVisible && activePost &&
                createPortal(
                    <LightboxModal closeModal={handleCloseModal}
                        post={activePost}
                        headerData={headerData}
                        feedSettings={feedSettings}
                        handleNext={handleNextItem}
                        handlePrev={handlePreviousItem}
                        showNext={
                            !(activeIndex === posts.length - 1)
                        }
                        showPrev={
                            !(activeIndex === 0)
                        }
                        isPro={isPro}
                        isLightboxVisible={isLightboxVisible}
                        setIsLightboxVisible={setIsLightboxVisible}
                    />,
                    document.body
                )
            }
        </>
    )
}

export default ListLayout;