const Author = ({headerData}) => {
    return (
        <div className='sb-post-item-author'>
            <img
                src={headerData?.local_avatar_url || headerData?.avatar_url_100}
                alt="profile"
                className='sb-post-item-author-image'
            />

            <p className='sb-post-item-author-name'>
                @{headerData?.username}
            </p>
        </div>
    )
}

export default Author;