import { __ } from '@wordpress/i18n';
import SbUtils from '../../../Utils/SbUtils'
import GridLayout from './GridLayout'
import CarouselLayout from './CarouselLayout';
import MasonryLayout from './MasonryLayout';
import ListLayout from './ListLayout';
import GalleryLayout from './GalleryLayout';

const PostsWrapper = ({ feedSettings, headerData, posts, isPro, isFeedEditor, feedId, editorActiveDevice = 'desktop' }) => {
    const layoutProps = {
        headerData,
        posts,
        feedSettings,
        editorActiveDevice,
        isPro,
        isFeedEditor,
        feedId
    }

    const postsLoopOutput = () => {
        switch (feedSettings.layout) {
            case 'grid':
                return (
                    <GridLayout
                        {...layoutProps}
                    />
                )
            case 'list':
                return (
                    <ListLayout
                        {...layoutProps}
                    />
                )
            case 'masonry':
                return (
                    <MasonryLayout
                        {...layoutProps}
                    />
                )
            case 'carousel':
                return (
                    <CarouselLayout
                        {...layoutProps}
                    />
                )
            case 'gallery':
                return (
                    <GalleryLayout
                        {...layoutProps}
                    />
                )
            default:
                return (
                    <GridLayout
                        {...layoutProps}
                    />
                )
        }
    }

    return (
        <section
            className='sb-feed-posts sb-fs'
            data-avatar-size='medium'
        >
            {posts.length > 0 && postsLoopOutput()}
            {isFeedEditor && SbUtils.addHighlighter('posts-layout', 'Posts Layout', 0, true)}
        </section>
    )
}
export default PostsWrapper;